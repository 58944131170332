import { Box, Flex, FlexProps, forwardRef, Heading } from '@chakra-ui/react';
import React from 'react';
import LogoSmall from '../../assets/logo-small-nt.png';

export const Brand = forwardRef<FlexProps, 'div'>((props, ref) => (
  <Flex ref={ref} align="center" {...props}>
    <Box as="img" src={LogoSmall} alt="UC Gaming Guild" mr="0" />
    <Heading fontSize="2xl" fontWeight="normal">
      UC Gaming Guild
    </Heading>
  </Flex>
));
