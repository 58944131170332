exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-the-club-tsx": () => import("./../../../src/pages/about-the-club.tsx" /* webpackChunkName: "component---src-pages-about-the-club-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-events-tsx": () => import("./../../../src/pages/our-events.tsx" /* webpackChunkName: "component---src-pages-our-events-tsx" */),
  "component---src-pages-our-history-tsx": () => import("./../../../src/pages/our-history.tsx" /* webpackChunkName: "component---src-pages-our-history-tsx" */),
  "component---src-pages-our-sponsors-tsx": () => import("./../../../src/pages/our-sponsors.tsx" /* webpackChunkName: "component---src-pages-our-sponsors-tsx" */)
}

