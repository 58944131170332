import { Box, Flex, FlexProps, Grid, HStack, Icon, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { FaDiscord, FaFacebookSquare, FaInstagram, FaTshirt, FaTwitch, FaTwitter, FaYoutube } from 'react-icons/fa';
import { uid } from 'react-uid';
import { Container } from '../Container';

const social = [
  {
    color: '#7289da',
    icon: FaDiscord,
    title: 'Discord',
    href: 'https://discord.gg/ucgg',
  },
  {
    color: '#3b5998',
    icon: FaFacebookSquare,
    title: 'Facebook',
    href: 'https://www.facebook.com/UCGamingGuild',
  },
  {
    color: '#e1306c',
    icon: FaInstagram,
    title: 'Instagram',
    href: 'https://instagram.com/UCGamingGuild',
  },
  {
    color: '#1da1f2',
    icon: FaTwitter,
    title: 'Twitter',
    href: 'https://twitter.com/UCGamingGuild',
  },
  {
    color: '#6441a5',
    icon: FaTwitch,
    title: 'Twitch',
    href: 'https://www.twitch.tv/ucgamingguild',
  },
  {
    color: '#c4302b',
    icon: FaYoutube,
    title: 'Youtube',
    href: 'https://www.youtube.com/channel/UCyTeMuddifF2p3cGo4FWiGQ',
  },
  {
    color: '#333',
    icon: FaTshirt,
    title: 'Merch',
    href: 'https://verboom.co.nz/?s=ucgg',
  },
];

export function Footer(props: FlexProps) {
  return (
    <Flex py={3} borderTopWidth="1px" fontSize="xs" {...props}>
      <Container>
        <Grid gap={3} templateColumns={['1fr', null, 'auto auto']} justifyContent="space-between" justifyItems="center">
          <Box textAlign={['center', null, 'left']}>
            <Text>&copy; UC Gaming Guild {new Date().getFullYear()}. All rights reserved.</Text>
            <Text>
              Maintained and hosted by{' '}
              <Link href="https://netspeed.net.nz?utm=ucgg" isExternal>
                Netspeed Canterbury
              </Link>
              .
            </Text>
          </Box>

          <HStack spacing={2}>
            {social.map(({ icon, color, title, href }) => (
              <Link key={uid(title)} href={href} isExternal fontSize="3xl" lineHeight={1} color={color} title={title}>
                <Icon as={icon} />
              </Link>
            ))}
          </HStack>
        </Grid>
      </Container>
    </Flex>
  );
}
