import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FlexProps,
  forwardRef,
  Grid,
  Icon,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import { FaArrowRight, FaBars } from 'react-icons/fa';
import { uid } from 'react-uid';
import { useMedia } from 'react-use';
import { Brand } from '../Brand';
import { Container } from '../Container';

const NAV_ITEMS: { label: ReactNode; to: string }[] = [
  { label: 'Home', to: '/' },
  { label: 'Events', to: '/our-events' },
  { label: 'Sponsors', to: '/our-sponsors' },
  { label: 'About', to: '/about-the-club' },
  { label: 'History', to: '/our-history' },
  { label: 'Contact', to: '/contact-us' },
];

const NavLink = forwardRef<FlexProps, 'div'>((props, ref) => (
  <Flex
    ref={ref}
    px={3}
    h={16}
    align="center"
    color="#9d9d9d"
    outline="none"
    _hover={{ bg: 'rgba(0, 0, 0, 0.3)', color: 'white' }}
    _focus={{ bg: 'rgba(0, 0, 0, 0.3)', color: 'white' }}
    _active={{ bg: '#080808', color: 'white' }}
    {...props}
  />
));

export function Navigation(props: FlexProps) {
  const isMobile = useMedia('(max-width: 992px)');

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (isMobile) {
    return (
      <>
        <Flex h={16} w="full" position="fixed" {...props}>
          <Container>
            <Grid h="full" gap={4} templateColumns={['auto auto']} justifyContent="space-between" alignContent="center">
              <Brand as={Link} to="/" />

              <Flex align="center">
                <Button leftIcon={<Icon as={FaBars} />} colorScheme="white" variant="ghost" size="sm" onClick={onOpen}>
                  Menu
                </Button>
              </Flex>
            </Grid>
          </Container>
        </Flex>

        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            {/* eslint-disable-next-line react/destructuring-assignment */}
            <DrawerHeader minH={16} bg={props.bg} color="white">
              Menu
            </DrawerHeader>
            <DrawerBody p={3}>
              <Stack spacing={3}>
                {NAV_ITEMS.map((item, i) => (
                  <Button
                    key={uid(item, i)}
                    as={Link}
                    to={item.to}
                    variant="ghost"
                    justifyContent="flex-start"
                    onClick={onClose}
                  >
                    {item.label}
                  </Button>
                ))}
                <Box>
                  <Button
                    as={Link}
                    to="/contact-us"
                    ml={3}
                    rightIcon={<Icon as={FaArrowRight} />}
                    colorScheme="teal"
                    onClick={onClose}
                  >
                    Join our Club
                  </Button>
                </Box>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  return (
    <Flex h={16} w="full" position="fixed" {...props}>
      <Container>
        <Grid h="full" gap={4} templateColumns={['auto auto']} justifyContent="space-between" alignContent="center">
          <Brand as={Link} to="/" />

          <Flex align="center">
            {NAV_ITEMS.map((item, i) => (
              <NavLink key={uid(item, i)} as={Link} to={item.to}>
                {item.label}
              </NavLink>
            ))}
            <Button
              as={Link}
              to="/contact-us"
              ml={3}
              rightIcon={<Icon as={FaArrowRight} />}
              colorScheme="teal"
              size="sm"
            >
              Join our Club
            </Button>
          </Flex>
        </Grid>
      </Container>
    </Flex>
  );
}
