import { baseTheme, defineStyleConfig, extendTheme } from '@chakra-ui/react';
import '@fontsource/bitter/latin.css';

const theme = {
  fonts: {
    heading: `Bitter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif`,
  },
  components: {
    Heading: defineStyleConfig({
      baseStyle: { fontWeight: 'normal' },
    }),
    Link: defineStyleConfig({
      baseStyle: { color: 'blue.500' },
    }),
  },
};

export default extendTheme(theme);
export { baseTheme };
