import { Flex } from '@chakra-ui/react';
import { PageProps } from 'gatsby';
import React, { ReactNode } from 'react';
import { Footer } from './Footer';
import { Navigation } from './Navigation';

export interface SiteProps extends Omit<PageProps, 'children'> {
  children: ReactNode;
}

export function Site({ children }: SiteProps) {
  return (
    <Flex h="100vh" direction="column" bg="gray.50">
      <Navigation bg="#222" color="white" />
      <Flex as="main" pt={16} direction="column" flex="1 1 auto" bg="white">
        {children}
      </Flex>
      <Footer bg="gray.50" />
    </Flex>
  );
}
